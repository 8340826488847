import { Avatar } from '@eventbrite/eds-avatar';
import { Button } from '@eventbrite/eds-button';
import { CalendarChunky as CalendarChunkySVG } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { Checkbox } from '@eventbrite/marmalade';
import {
    FOLLOWED_ORGS_FILTER_VALUE,
    SPECIAL_FILTER,
} from '@eventbrite/search-utils';
import { setAction } from '@eventbrite/user-action-cookie';
import React, { useContext } from 'react';
import { ConfirmationModalBody } from '../../../../components/ConfirmationOverlay';
import OverlayContext from '../../../../context/Overlay';
import { SelectedFilters } from '../../../../types/index';
import './EventsFilter.scss';

export interface FollowedOrganizersFilterButtonProps {
    selectedFilters?: SelectedFilters;
    onFilterChange?: Function;
    handleRemoveFilter?: Function;
    isAuthenticated?: boolean;
    isMobile?: boolean;
}

const SIGNIN_CONFIRMATION_TEXT = gettext(
    'Sign in to see events </br>from organizers you follow',
);

const SigninConfirmationModalContent = () => {
    // Don't try to render the URL during SSR, but instead render no referrer
    // first for hydration:
    const [referrerPath, setReferrerPath] = React.useState('');
    React.useEffect(() => {
        const referrer = `${window.location.pathname}${
            window.location.search ? window.location.search + '&' : '?'
        }followed_orgs=1`;
        setReferrerPath(`?referrer=${referrer}`);
    }, []);

    return (
        <ConfirmationModalBody
            contentHeader={
                <Avatar
                    iconType={<CalendarChunkySVG />}
                    backgroundColor="grey-100"
                />
            }
            contentFooter={
                <Button
                    type="link"
                    style="fill"
                    href={`/signin/${referrerPath}`}
                >
                    {gettext('Get Started')}
                </Button>
            }
        >
            <h2 className="eds-text-hs eds-text--center">
                <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: SIGNIN_CONFIRMATION_TEXT.toString(),
                    }}
                />
            </h2>
        </ConfirmationModalBody>
    );
};

const FollowedOrganizersFilterButton = ({
    onFilterChange,
    handleRemoveFilter,
    isMobile,
    isAuthenticated,
    selectedFilters,
}: FollowedOrganizersFilterButtonProps) => {
    const { setOverlayConfig } = useContext(OverlayContext) || {};
    const loggedInHandleClick = () => {
        !selectedFilters?.followedOrganizers
            ? onFilterChange?.(SPECIAL_FILTER, FOLLOWED_ORGS_FILTER_VALUE)
            : handleRemoveFilter?.({ special: true });
    };

    const loggedOutHandleClick = () => {
        setAction({
            action: 'FollowedOrganizerFilterAttempt',
            origin: '/',
        });

        setOverlayConfig?.({
            type: 'simple',
            children: <SigninConfirmationModalContent />,
        });
    };

    return (
        <div className="filterContainer">
            <Checkbox
                id={`followedOrganizersFilterButton__${
                    isMobile ? 'mobile' : 'desktop'
                }`}
                name={`followedOrganizersFilterButton__${
                    isMobile ? 'mobile' : 'desktop'
                }`}
                value={`followedOrganizers__${isMobile ? 'mobile' : 'desktop'}`}
                onChange={
                    isAuthenticated ? loggedInHandleClick : loggedOutHandleClick
                }
                className="checkBox"
                checked={selectedFilters?.followedOrganizers}
                aria-label={gettext('Show events from followed organizers')}
            />
            <div className="filterText">
                {gettext('Only show events from organizers I follow')}
            </div>
        </div>
    );
};

export default FollowedOrganizersFilterButton;
