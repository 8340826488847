import { gettext } from '@eventbrite/i18n';
import {
    Button,
    ChevronDownIcon,
    IconButton,
    Typography,
} from '@eventbrite/marmalade';
import { MobileFilterPanelProps } from '@eventbrite/search-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import React, { MouseEventHandler } from 'react';
import { AnimatedDrawer } from '../AnimatedDrawer';
import FilterPanel from './FilterPanel';
import './FilterPanel.scss';
interface MobileFilterPanelHeaderProps {
    onClick: MouseEventHandler<HTMLElement>;
}

export const MobileFilterHeader: React.FunctionComponent<
    MobileFilterPanelHeaderProps
> = (props) => {
    return (
        <div
            style={{
                position: 'sticky',
                minHeight: '42px',
                backgroundColor: 'white',
                display: 'flex',
                zIndex: 1000,
                top: '0px',
                padding: '8px 0px 4px 0px',
                flexDirection: 'column',
            }}
            className="marmalade-filter-panel-mobile-header"
            data-testid="mobile-filter-overlay-header"
        >
            <div
                style={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <Typography variant="heading-sm" color={'neutral-1000'}>
                    {gettext('Filters')}
                </Typography>
                <IconButton
                    onClick={props.onClick}
                    icon={<ChevronDownIcon />}
                    data-testid="mobile-filter-overlay-header--close"
                    aria-label={gettext('Close filter overlay')}
                />
            </div>
            {props.children}
        </div>
    );
};

interface MobileFilterFooterProps {
    onClick: () => void;
}

const MobileFilterFooter: React.FunctionComponent<MobileFilterFooterProps> = (
    props,
) => {
    const [render, setRender] = React.useState(false);

    //Delay the rendering to let the filter panel animation
    //conclude before rendering
    React.useEffect(() => {
        setTimeout(() => {
            setRender(true);
        }, 200);
    }, []);

    if (!render) {
        return null;
    }

    return (
        <section
            style={{
                position: 'fixed',
                padding: '16px',
                left: 0,
                right: 0,
                bottom: 0,
                background: 'white',
            }}
            data-testid="mobile-filter-overlay-footer"
        >
            <Button
                onClick={props.onClick}
                variant="primary"
                style={{ width: '100%' }}
            >
                {gettext('View event results')}
            </Button>
        </section>
    );
};

export const FilterPanelMobile: React.FunctionComponent<
    MobileFilterPanelProps
> = (props) => {
    const applyFilter = (...args: $FixMe) => {
        props.onFilterChange?.apply(null, args);
    };

    return (
        <AnimatedDrawer>
            <section
                data-testid="filter-panel-mobile-container"
                style={{
                    zIndex: 1000,
                    position: 'relative',
                    display: 'block',
                    backgroundColor: 'white',
                    paddingBottom: '60px',
                }}
            >
                <FilterPanel
                    {...props}
                    isMobile={true}
                    onFilterChange={applyFilter}
                    header={
                        <MobileFilterHeader
                            key="filter-panel-mobile-filter-header"
                            onClick={() =>
                                props?.openCloseAnimatedDrawer?.(false)
                            }
                        >
                            {props.headerChildren}
                        </MobileFilterHeader>
                    }
                    footer={
                        <MobileFilterFooter
                            onClick={() =>
                                props.openCloseAnimatedDrawer?.(false)
                            }
                        />
                    }
                />
            </section>
        </AnimatedDrawer>
    );
};
export default FilterPanelMobile;
