import {
    FORMATTED_SUBCATEGORY_MAP,
    SUBCATEGORY_REFINEMENT_TO_KEY,
} from '@eventbrite/categories';
import { EVENTBRITE_CATEGORY_MAPPING_SEARCH } from '@eventbrite/search-utils';

/**
Generates an object of categories and subcategories, with their translated titles
{
    [categoryId]: {
        title,
        values: [{ key: subcategoryId, title }]
    }
}
*/
export const CATEGORY_SUBCATEGORY_MAPPING_SEARCH = Object.fromEntries(
    Object.entries(EVENTBRITE_CATEGORY_MAPPING_SEARCH).map(
        ([categoryId, categoryTitle]) => {
            const subcategories = SUBCATEGORY_REFINEMENT_TO_KEY[categoryId];
            return [
                categoryId,
                {
                    title: categoryTitle,
                    values: Object.entries(subcategories)
                        .filter(
                            ([subcategoryRefinement, _subcategoryId]) =>
                                subcategoryRefinement !== 'other',
                        )
                        .map(([_subcategoryRefinement, subcategoryId]) => {
                            return {
                                key: subcategoryId,
                                title: FORMATTED_SUBCATEGORY_MAP[subcategoryId]
                                    .title,
                            };
                        }),
                },
            ];
        },
    ),
);
