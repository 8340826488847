import { gettext } from '@eventbrite/i18n';
import { getOnlineEventsOption } from '@eventbrite/location-autocomplete';
import { Checkbox } from '@eventbrite/marmalade';
import React from 'react';
import { SelectedFilters } from '../../../../types/index';
import './EventsFilter.scss';

interface OnlineEventsFilterButtonProps {
    selectedFilters?: SelectedFilters;
    handleRemoveOnlineFilter?: Function;
    handleApplyOnlineFilter?: Function;
    isMobile?: boolean;
}

const OnlineEventsFilterButton = (props: OnlineEventsFilterButtonProps) => {
    return (
        <div className="onlineFilterContainer">
            <Checkbox
                id={`onlineEventsFilterButton__${
                    props.isMobile ? 'mobile' : 'desktop'
                }`}
                name={`onlineEventsFilterButton__${
                    props.isMobile ? 'mobile' : 'desktop'
                }`}
                value={`onlineEventsFilter__${
                    props.isMobile ? 'mobile' : 'desktop'
                }`}
                onChange={() =>
                    props.selectedFilters?.isOnline
                        ? props.handleRemoveOnlineFilter?.()
                        : props.handleApplyOnlineFilter?.({
                              ...getOnlineEventsOption(),
                              placeId: null,
                          })
                }
                checked={props?.selectedFilters?.['isOnline']}
                aria-label={gettext('Show online events')}
            />
            <p className="onlineEventsFilter">
                {gettext('Search for online events')}
            </p>
        </div>
    );
};

export default OnlineEventsFilterButton;
