import { logEvent } from '@eventbrite/statsig';

const TRACK_ANY_FILTER_CLICK_STATSIG = 'Search - Filters - Click - Any';
const getTrackFilterTypeString = (filterType: string): string =>
    `Search - Filters - Click - ${filterType}`;

export const trackFiltersForStatsig = (key: string) => {
    logEvent(TRACK_ANY_FILTER_CLICK_STATSIG, 1);
    logEvent(getTrackFilterTypeString(key), 1);
};
