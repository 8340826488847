import { openCloseAnimatedDrawer as openCloseAnimatedDrawerAction } from '@eventbrite/search-utils';
import { $FixMe } from '@eventbrite/ts-utils';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import './AnimatedDrawer.scss';

const Modal = lazy(() => import('./Modal'));

interface AnimatedDrawerProps {
    openCloseAnimatedDrawer: Function;
    rootId?: string;
    showOverlay?: boolean;
}

interface AnimatedDrawerState {
    isMounted: boolean;
}

export class AnimatedDrawer extends React.Component<
    AnimatedDrawerProps,
    AnimatedDrawerState
> {
    state = {
        isMounted: false,
    };
    filterPanelMobileMount!: Element;

    componentDidMount() {
        this.setState({ isMounted: true });
    }

    render() {
        if (this.state.isMounted) {
            const { openCloseAnimatedDrawer, showOverlay } = this.props;
            return (
                <Suspense fallback={<div />}>
                    <Modal
                        noPadding={true}
                        isShown={this.state.isMounted}
                        __containerClassName="animated-drawer-overrides"
                    >
                        {showOverlay && (
                            <div
                                onClick={() => {
                                    openCloseAnimatedDrawer?.(false);
                                }}
                                className="animated-drawer__overlay"
                            />
                        )}
                        <div className="animated-drawer__content">
                            {this.props.children}
                        </div>
                    </Modal>
                </Suspense>
            );
        }

        return null;
    }
}

const _mapDispatchToProps = (dispatch: Function) => ({
    openCloseAnimatedDrawer: (...args: [$FixMe]) =>
        dispatch(openCloseAnimatedDrawerAction(...args)),
});

export default connect(undefined, _mapDispatchToProps)(AnimatedDrawer);
