import * as React from 'react';

const CalendarChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="calendar-chunky_svg__eds-icon--calendar-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="calendar-chunky_svg__eds-icon--calendar-chunky_base"
            d="M16.9 6.5v-2h-2v2h-6v-2h-2v2h-2v13h14v-13h-2zm0 11h-10v-7h10v7z"
        />
    </svg>
);

CalendarChunkySvg.displayName = 'CalendarChunkySvg';
export default CalendarChunkySvg;
