import {
    CurrencyFilters,
    ExperiencesFilters,
    FilterProps,
    FormatFilters,
    LanguageFilters,
    PriceFilters,
} from '@eventbrite/search-utils';
import React from 'react';
import { DateFilters } from './EventFilters';
import FollowedOrganizersFilterButton from './FollowedOrganizersFilterButton';
import { NestedCategoryFilters as CategoryFilters } from './NestedFilters';
import OnlineEventsFilterButton from './OnlineEventsFilterButton';

const Filters: React.FunctionComponent<FilterProps> = (props) => {
    return (
        <div>
            <CategoryFilters {...props} />
            <DateFilters {...props} />
            <ExperiencesFilters {...props} />
            <PriceFilters {...props} />
            <FormatFilters {...props} />
            <FollowedOrganizersFilterButton {...props} />
            <OnlineEventsFilterButton {...props} />
            <LanguageFilters {...props} />
            <CurrencyFilters {...props} />
        </div>
    );
};

export default Filters;
