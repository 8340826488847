import { DesktopFilterPanelProps } from '@eventbrite/search-utils';
import React from 'react';
import FilterPanel from './FilterPanel';

export const FilterPanelDesktop: React.FunctionComponent<
    DesktopFilterPanelProps
> = (props) => {
    const handleFilterChange = (...args: any) => {
        props.onFilterChange?.apply(null, args);
        // Scroll back to top of screen, otherwise it looks strange
        window.scrollTo(0, 0);
    };

    return <FilterPanel {...props} onFilterChange={handleFilterChange} />;
};
