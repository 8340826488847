import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import { FilterPanelProps } from '@eventbrite/search-utils';
import React from 'react';
import './FilterPanel.scss';
import Filters from './Filters';

const filters = gettext('Filters');

export const DefaultHeader: React.FunctionComponent = () => {
    return (
        <Typography variant="heading-sm" color={'neutral-1000'}>
            {filters}
        </Typography>
    );
};

const FilterPanel: React.FunctionComponent<FilterPanelProps> = (props) => {
    const header = props.header ? props.header : <DefaultHeader />;
    const footer = props.footer;

    return (
        <div className="filter-panel">
            {header}
            <Filters {...props} />
            {footer}
        </div>
    );
};

export default FilterPanel;
