import { CUSTOM_DATE } from '@eventbrite/discover-utils';
import {
    constructFilterItemKey,
    DATES_MAPPING_SEARCH,
    DATE_FILTER,
    FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP,
    FilterItem,
    FilterProps,
    FilterSection,
    RadioType,
} from '@eventbrite/search-utils';
import loadable from '@loadable/component';
import queryString from 'query-string';
import React, { useState } from 'react';

const InlineDatePicker = loadable(() => import('../InlineDatePicker'));

export const DateFilters: React.FunctionComponent<FilterProps> = (props) => {
    const urlParams = queryString.parse(props.location.search.replace('?', ''));
    const [currentDateOption, setCurrentDateOption] = useState<string>('');
    const activeFilterOption = props.selectedFilters?.[DATE_FILTER]?.toString();

    const handleOptionChange = (value: string) => {
        if (value !== CUSTOM_DATE) {
            props.onFilterChange?.(DATE_FILTER, value);
            setCurrentDateOption(value);
        } else if (currentDateOption === CUSTOM_DATE) {
            setCurrentDateOption('');
        } else {
            setCurrentDateOption(value);
        }
    };

    const handleCustomDateSelect = (startDate: string, endDate: string) => {
        setCurrentDateOption('');

        props?.onCustomDateChange?.(startDate, endDate);
    };

    return (
        <FilterSection
            filters={{
                ...DATES_MAPPING_SEARCH,
            }}
            onOptionChange={(val: string) => handleOptionChange(val)}
            title={FACETED_SEARCH_DROPDOWN_FILTER_PROP_MAP[DATE_FILTER].label}
            activeFilterOption={activeFilterOption}
            isMobile={props.isMobile}
            shouldScrollIntoView={props.filterTypeToShow === DATE_FILTER}
        >
            {(filterItem: RadioType) => {
                const shouldRenderDatePicker =
                    currentDateOption === CUSTOM_DATE &&
                    filterItem.value === CUSTOM_DATE;
                return (
                    <FilterItem
                        key={constructFilterItemKey(
                            'date',
                            filterItem.value,
                            props.isMobile,
                        )}
                        value={filterItem.value}
                        display={filterItem.display}
                    >
                        {shouldRenderDatePicker ? (
                            <InlineDatePicker
                                onDatePickerChange={handleCustomDateSelect}
                                urlParams={urlParams}
                            />
                        ) : null}
                    </FilterItem>
                );
            }}
        </FilterSection>
    );
};
