import { GenericLazyString } from '@eventbrite/i18n';
import { Checkbox } from '@eventbrite/marmalade';
import {
    FilterItem,
    FilterItemProps,
    FilterItemTokens,
    FilterSection,
    FilterSectionProps,
} from '@eventbrite/search-utils';
import React from 'react';
import './NestedFilterItem.scss';

interface NestedFilterItemTokens extends FilterItemTokens {
    '--NestedFilterItemMargin'?: string;
}

type FilterMapProps = {
    [key: string]: {
        title: GenericLazyString;
        values: { key: string; title: GenericLazyString }[];
    };
};

type NestedFilterProps = Pick<
    FilterSectionProps,
    'activeFilterOption' | 'isMobile' | 'onOptionChange'
> &
    FilterItemProps & {
        subFilterMap: FilterMapProps;
        tokens: NestedFilterItemTokens;
        initialToggleOpen?: boolean;
    };

function getSubFilters(titleToSearch: string, map: FilterMapProps) {
    const category = map[titleToSearch];
    if (category) {
        return category.values.map(({ key, title }) => ({
            display: title,
            value: key,
        }));
    }
    return {};
}

export const NestedFilterItem: React.FunctionComponent<NestedFilterProps> = ({
    ChoiceComponent = Checkbox,
    value,
    subFilterMap,
    activeFilterOption,
    isMobile,
    tokens,
    onOptionChange,
}) => {
    const radioValues = getSubFilters(value, subFilterMap);

    return (
        <div className="subFilterHeading" style={tokens as React.CSSProperties}>
            <FilterSection
                filters={radioValues}
                onOptionChange={onOptionChange}
                activeFilterOption={activeFilterOption}
                isMobile={isMobile}
                tokens={tokens}
            >
                {(filterItem) => {
                    return (
                        <FilterItem
                            key={filterItem.value}
                            value={filterItem.value}
                            display={filterItem.display}
                            ChoiceComponent={ChoiceComponent}
                        />
                    );
                }}
            </FilterSection>
        </div>
    );
};
